<template id="optasetup">
  <b-container fluid>
    <h3>Player Index Game</h3>

    <b-row class="mb-3">
      <b-col>
        <b-form-input
          v-model="form.player_id"
          type="text"
          placeholder="Player ID"
        ></b-form-input>
      </b-col>
      <b-col>
        <b-button
          :variant="waiting_index ? 'warning' : 'primary'"
          @click="getPlayerIndexGame"
          :disabled="waiting_index"
          >Get index game
          <b-icon
            icon="arrow-repeat"
            :animation="waiting_index ? 'spin' : ''"
          ></b-icon
        ></b-button>
        <b-btn class="ml-2" variant="secondary" title="Info" v-b-toggle.collapse
          ><b-icon icon="info-circle"></b-icon
        ></b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-collapse id="collapse" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col>
              <p>
                <strong>Micro basic</strong>: index calcolato sulle skills base
                (senza algoritmi) di tipo micro del gruppo ruolo di riferimento,
                senza normalizzare i valori tra 0 e 1, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo (Micro/Weigth Player
                Basic Skill Setup)
              </p>
              <p>
                <strong>Macro basic</strong>: index calcolato su tutte le skills
                base (senza algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Basic Skill Setup)
              </p>
              <p>
                <strong>Micro raw</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, senza
                normalizzare i valori tra 0 e 1, moltiplicate per i relativi
                pesi impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro raw</strong>: index calcolato su tutte le skills
                (con algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro</strong>: index calcolato su tutte le skills (con
                algoritmi), con la normalizzare dei valori delle skilla tra 0 e
                1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato su tutte le skills
                (con algoritmi), con la normalizzare dei valori delle skilla tra
                0 e 1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato sulle skills (con algoritmi) di tipo micro del gruppo
                ruolo di riferimento, con la normalizzare dei valori delle
                skilla tra 0 e 1 sui giocatori coinvolti nella partita,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato su tutte le skills (con algoritmi), con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo di riferimento (Micro/Weigth Player
                Setup)
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>

    <b-table
      id="my-table"
      ref="table"
      sticky-header="600px"
      small
      striped
      dark
      responsive
      :fields="fields"
      :items="player_index_games"
      :tbody-tr-class="rowClass"
    >
      <template #cell(index)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.index + 1 }}
        </span>
      </template>
      <template #cell(player)="data">
        <span style="width: 200px; white-space: nowrap; overflow: hidden">
          <b-row>
            <b-col cols="1">
              <b-form-checkbox
                name="games_sel"
                v-model="index_game_ids"
                :value="data.item.id"
              >
              </b-form-checkbox>
            </b-col>
            <b-col>
              <span
                :style="
                  index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
                "
              >
                {{ data.value.player }}
              </span>
            </b-col>
          </b-row>
        </span>
      </template>
      <template #cell(game)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value.label }}
        </span>
      </template>
      <template #cell(date)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value | formatDate }}
        </span>
      </template>
      <template #cell(micro_index_basic)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(macro_index_basic)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(micro_index_raw)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(macro_index_raw)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(micro_index)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(macro_index)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(micro_index_norm)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(macro_index_norm)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(micro_index_group_norm)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(macro_index_group_norm)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ Math.round(data.value * 100) / 100 }}
        </span>
      </template>
      <template #cell(group)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
      <template #cell(minutes)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
    </b-table>
    <b-row align-v="center" v-if="items.length > 0" class="mb-3">
      <!--b-col cols="2"> Players selected: {{ players_ids.length }} </b-col>
  <b-col cols="2">
    <b-input-group>
      <b-form-input
      id="filter-input"
      v-model="filter"
      type="search"
      placeholder="Search player"
      ></b-form-input>

      <b-input-group-append>
        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
  </b-col-->
      <b-col cols="1" class="text-right"> Per page </b-col>
      <b-col cols="1">
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          class="mb-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
//import JsonExcel from "vue-json-excel";
import loading from "@/assets/images/loading.gif";
export default {
  data: function () {
    return {
      ita: true,
      form: {
        player_id: null,
        from: null,
        to: null,
        cluster_ids: [],
        group: null,
        minutes: 300,
        age_from: 0,
        age_to: 0,
      },
      groups: [
        { id: null, name: "-- Select --" },
        { id: 0, name: "PORTIERI" },
        { id: 1, name: "DIFENSORI CENTRALI" },
        { id: 2, name: "ESTERNI" },
        { id: 3, name: "CENTROCAMPISTI CENTRALI" },
        { id: 4, name: "INTERNI DI CENTROCAMPO" },
        { id: 5, name: "ATTACCANTI CENTRALI" },
        { id: 6, name: "ATTACCANTI ESTERNI" },
      ],
      minutes: [300, 600, 900],
      loading,
      waiting: false,
      clusters: [],
      players: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "player",
          sortable: true,
          label: "Player",
          thStyle: { "min-width": "150px" },
        },
        {
          key: "game",
          sortable: true,
          label: "Game",
          thStyle: { "min-width": "100px" },
        },
        {
          key: "date",
          sortable: true,
          label: "Date",
          thStyle: { "min-width": "100px" },
        },
        {
          key: "micro_index_basic",
          sortable: true,
          label: "Micro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_basic",
          sortable: true,
          label: "Macro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_raw",
          sortable: true,
          label: "Micro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_raw",
          sortable: true,
          label: "Macro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index",
          sortable: true,
          label: "Micro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index",
          sortable: true,
          label: "Macro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_norm",
          sortable: true,
          label: "Micro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_norm",
          sortable: true,
          label: "Macro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_group_norm",
          sortable: true,
          label: "Micro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_group_norm",
          sortable: true,
          label: "Macro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        { key: "group", sortable: true, label: "Group", class: "text-center" },
        { key: "minutes", sortable: true, label: "Min", class: "text-center" },
      ],
      items: [],
      players_ids: [],
      waiting_index: false,
      skill_range: {},
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
        "#FF9999",
      ],
      totalRows: 1,
      perPage: 30,
      currentPage: 1,
      filter: null,
      allSelectedCluster: false,
      indeterminate: false,

      player_index_games: [],
      player: null,
      index_game_ids: [],
    };
  },

  components: {
    //  JsonExcel,
  },

  created: function () {
    //  this.getClusters();
  },

  watch: {
    "form.cluster_ids"(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelectedCluster = false;
      } else if (newValue.length === this.clusters.length) {
        this.indeterminate = false;
        this.allSelectedCluster = true;
      } else {
        this.indeterminate = true;
        this.allSelectedCluster = false;
      }
    },
  },

  methods: {
    toggleAllCluster(checked) {
      if (checked) {
        for (var i in this.clusters) {
          if (!this.form.cluster_ids.includes(this.clusters[i].id)) {
            this.form.cluster_ids.push(this.clusters[i].id);
          }
        }
      } else {
        this.form.cluster_ids = [];
      }
    },

    getClusters() {
      this.$http.get("/opta/clusters").then((response) => {
        this.clusters = response.data;
      });
    },

    getPlayerIndexGame() {
      if (
        this.form.player_id == null ||
        this.form.player_id === "" ||
        isNaN(this.form.player_id)
      ) {
        alert("Insert a valid player ID!");
        return;
      }
      this.waiting_index = true;
      this.player_index_games = [];
      this.index_game_ids = [];

      this.$http.post("/player/index/game", this.form).then((response) => {
        this.player_index_games = response.data.player_index_games;
        this.totalRows = this.items.length;
        this.waiting_index = false;
      });
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    rowClass(item, type) {
      if (!item || type !== "row") {
        return "";
      }
      if (this.index_game_ids.includes(item.id)) {
        return "table-success";
      }
      return "";
    },
  },

  computed: {
    excel_data: function () {
      var data = [];
      for (var i in this.items) {
        var item = this.items[i];
        var row = {
          giocatore: item.player.name,
          ruolo: item.player.role,
          index: item.index,
          age: item.age,
        };
        for (var s in this.skill_princ_fields) {
          var skill_id = this.skill_princ_fields[s];
          var skill = this.getSkillById(skill_id);
          row[skill.name] = this.norm_view
            ? item["norm_" + skill_id]
            : item[skill_id];
        }
        for (var s in this.skill_other_fields) {
          var skill_id = this.skill_other_fields[s];
          var skill = this.getSkillById(skill_id);
          row[skill.name] = this.norm_view
            ? item["norm_" + skill_id]
            : item[skill_id];
        }
        row["bravo"] = this.players_ids.includes(item.player.id) ? 1 : 0;
        data.push(row);
      }
      var row_ins = {
        giocatore: "INSERIRE",
        ruolo: "",
        index: "",
        age: "",
      };
      var row_corr = {
        giocatore: "CORRELAZIONE",
        ruolo: "",
        index: "",
        age: "",
      };
      for (var s in this.skill_princ_fields) {
        var skill_id = this.skill_princ_fields[s];
        var skill = this.getSkillById(skill_id);

        row_ins[skill.name] = this.skills_ids.includes(skill_id) ? 1 : 0;
        row_corr[skill.name] = this.correlations[skill_id];
      }
      for (var s in this.skill_other_fields) {
        var skill_id = this.skill_other_fields[s];
        var skill = this.getSkillById(skill_id);

        row_ins[skill.name] = this.skills_ids.includes(skill_id) ? 1 : 0;
        row_corr[skill.name] = this.correlations[skill_id];
      }
      row_ins["bravo"] = "";
      row_corr["bravo"] = "";
      data.push(row_ins);
      data.push(row_corr);
      return data;
    },

    pageOptions() {
      return [15, 30, 50, 100, { value: this.totalRows, text: "All" }];
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
